/* eslint-disable quote-props */

export const channelNameMap = {
  'EEGF4-A1': 'EEG F4-A1',
  'EEGC4-A1': 'EEG C4-A1',
  'EEGO2-A1': 'EEG O2-A1',
  'EEGF3-A2': 'EEG F3-A2',
  'EEGC3-A2': 'EEG C3-A2',
  'EEGO1-A2': 'EEG O1-A2',
  'EOGE1-A2': 'EOG E1-A2',
  'EOGE2-A2': 'EOG E2-A2',
  'EMGCHIN': 'EMG chin',
  'EMGRAT': 'EMG RAT',
  'EMGLAT': 'EMG LAT',
  'ECG': 'ECG',
  'RESPNASAL': 'Resp nasal',
  'RESPCHEST': 'Resp chest',
  'RESPABDOMEN': 'Resp abdomen',
  'SOUNDSNORING': 'Sound snoring',
  'SAO2': 'SaO2',
  'POSITIONBODY': 'Position body',
  'F4-A1': 'EEG F4-A1',
  'F4-M1': 'EEG F4-A1',
  'F4-M1,EEG': 'EEG F4-A1',
  'EEG(F4-M1)': 'EEG F4-A1',
  'C4-A1': 'EEG C4-A1',
  'C4-A1_': 'EEG C4-A1',
  'C4-M1': 'EEG C4-A1',
  'C4M1': 'EEG C4-A1',
  'C4-M1,EEG': 'EEG C4-A1',
  'EEG(C4-M1)': 'EEG C4-A1',
  'O2-A1': 'EEG O2-A1',
  'O2-M1': 'EEG O2-A1',
  'O2M1': 'EEG O2-A1',
  'O2-M1,EEG': 'EEG O2-A1',
  'EEG(O2-M1)': 'EEG O2-A1',
  'F3-A2': 'EEG F3-A2',
  'F3-M2': 'EEG F3-A2',
  'F3-M2,EEG': 'EEG F3-A1',
  'EEG(F3-M2)': 'EEG F3-A2',
  'C3-A2': 'EEG C3-A2',
  'C3-A2_': 'EEG C3-A2',
  'C3-M2': 'EEG C3-A2',
  'C3M2': 'EEG C3-A2',
  'C3-M2,EEG': 'EEG C3-A2',
  'EEG(C3-M2)': 'EEG C3-A2',
  'O1-A2': 'EEG O1-A2',
  'O1-M1': 'EEG O1-A2',
  'O1-M2': 'EEG O1-A2',
  'O1M2': 'EEG O1-A2',
  'O1-M2,EEG': 'EEG O1-A2',
  'EEG(O1-M2)': 'EEG O1-A2',
  'E1-M2': 'EOG E1-A2',
  'E1': 'EEG E1',
  'EO1': 'EOG E1-A2',
  'EOG1': 'EOG E1',
  'EOGLEFT': 'EOG E1',
  'EOGLOC': 'EOG E1',
  'LOC': 'EOG E1',
  'LOC_': 'EOG E1',
  'LOC-A2': 'EOG E1-A2',
  'AUGE(L)': 'EOG E1',
  'EOGP18-M1': 'EOG E1-A1',
  'POS18-A1': 'EOG E1-A1',
  'POS18-M1': 'EOG E1-A1',
  'POS18M1': 'EOG E1-A1',
  'POS18-M,EOG': 'EOG E1-A',
  'POS18-M1,EOG': 'EOG E1-A1',
  'POS18-M1-M1,E': 'EOG E1-A1',
  'EOGVERTICAL': 'EOG vertical',
  'EOGVERT': 'EOG vertical',
  'AUVERT': 'EOG vertical',
  'AUGE(V)': 'EOG vertical',
  'E2-M1': 'EOG E2-A1',
  'E2': 'EEG E2',
  'EO2': 'EOG E2',
  'EOG2': 'EOG E2',
  'EOGRIGHT': 'EOG E2',
  'EOGROC': 'EOG E2',
  'ROC': 'EOG E2',
  'ROC_': 'EOG E2',
  'ROC-A1': 'EOG E2-A1',
  'AUGE(R)': 'EOG E2',
  'EOGP8-M1': 'EOG E2',
  'POS8-A1': 'EOG E2-A1',
  'POS8-M1': 'EOG E2-A1',
  'POS8M1': 'EOG E2-A1',
  'POS8.M1': 'EOG E2-A1',
  'POS8-M1,EOG': 'EOG E2-A1',
  'POS8-M1-M1,EO': 'EOG E2-A1',
  'EOGHORIZONTAL': 'EOG horizontal',
  'EOG   horizontal': 'EOG horizontal',
  'EOGHORIZ': 'EOG horizontal',
  'AUHOR': 'EOG horizontal',
  'AUGE(H)': 'EOG horizontal',
  'EMG(CHIN)': 'EMG chin',
  'KINN(M)': 'EMG chin',
  'KINN': 'EMG chin',
  'KINN_': 'EMG chin',
  'CHIN': 'EMG chin',
  'CHIN1': 'EMG chin',
  'CHIN2': 'EMG chin',
  'CHIN3': 'EMG chin',
  'CHIN1-CHIN2': 'EMG chin',
  'EMG1': 'EMG chin',
  'EMG2': 'EMG chin',
  'EMGMENT': 'EMG chin',
  'EMGMENTA': 'EMG chin',
  'EMGMENTAL': 'EMG chin',
  'EMGMENTALIS': 'EMG chin',
  'EMGMENT,EMG': 'EMG chin',
  'EMGMENTAL,EMG': 'EMG chin',
  'BEINRECHTS': 'EMG RAT',
  'BEIN(R)': 'EMG RAT',
  'REBEIN': 'EMG RAT',
  'EMGTIBIALR': 'EMG RAT',
  'EMGTIBRIGHT': 'EMG RAT',
  'EMGEMGTIBRI': 'EMG RAT',
  'EMG-TIB-RE': 'EMG RAT',
  'RIGHTLEG': 'EMG RAT',
  'RLEG': 'EMG RAT',
  'RLEG1': 'EMG RAT',
  'RLEG2': 'EMG RAT',
  'R-LEG': 'EMG RAT',
  'RAT': 'EMG RAT',
  'RAT2': 'EMG RAT',
  'LEG-R1': 'EMG RAT',
  'LEG-R2': 'EMG RAT',
  'LEG/R': 'EMG RAT',
  'LEGS': 'EMG UAT',
  'LEGBEIN2': 'EMG RAT',
  'LEGBEINRE': 'EMG RAT',
  'LEGLEG-R': 'EMG RAT',
  'BEINLINKS': 'EMG LAT',
  'BEIN(L)': 'EMG LAT',
  'LIBEIN': 'EMG LAT',
  'EMGLEFT': 'EMG LAT',
  'EMGTIBIALL': 'EMG LAT',
  'EMGTIBLEFT': 'EMG LAT',
  'EMGEMGTIBLE': 'EMG LAT',
  'EMG-TIB-LI': 'EMG LAT',
  'LEFTLEG': 'EMG LAT',
  'LLEG': 'EMG LAT',
  'LLEG1': 'EMG LAT',
  'LLEG2': 'EMG LAT',
  'L-LEG': 'EMG LAT',
  'LAT': 'EMG LAT',
  'LAT2': 'EMG LAT',
  'LEG-L1': 'EMG LAT',
  'LEG-L2': 'EMG LAT',
  'LEG/L': 'EMG LAT',
  'LEGBEIN1': 'EMG LAT',
  'LEGBEINLI': 'EMG LAT',
  'LEGLEG-L': 'EMG LAT',
  'EKG': 'ECG',
  'EKG_': 'ECG',
  'EKG1': 'ECG',
  'EKG2': 'ECG',
  'ECG1': 'ECG',
  'ECG2': 'ECG',
  'ECGI': 'ECG',
  'ECGII': 'ECG',
  'ECG,ECG': 'ECG',
  'ECG1-ECG2': 'ECG',
  'NAS': 'Resp nasal',
  'AIRFLOW': 'Resp nasal',
  'AIRFLOW,': 'Resp nasal',
  'AIRFLOW,FLOW': 'Resp nasal',
  'FLOW': 'Resp nasal',
  'FLOW_': 'Resp nasal',
  'FLOW_CU': 'Resp nasal',
  'FLUSS_CU': 'Resp nasal',
  'XFLOW_CU': 'Resp nasal',
  'FLOWPATIENT': 'Resp nasal',
  'RESPIRATORYFLOW': 'Resp nasal',
  'NASAL-TRANS': 'Resp nasal',
  'NASALPRESS.': 'Resp nasal',
  'NASALPRESSURE': 'Resp nasal',
  'THERMISTOR': 'Resp nasal',
  'PNEUMOFLOW': 'Resp nasal',
  'CHEST': 'Resp chest',
  'CHEST,': 'Resp chest',
  'RESP.CHEST': 'Resp chest',
  'CHEST,EFFORT': 'Resp chest',
  'CHESTWALLMOV.': 'Resp chest',
  'CHESTMOVEMENTS': 'Resp chest',
  'THO': 'Resp chest',
  'THOR': 'Resp chest',
  'THORAX': 'Resp chest',
  'EFFORTTHO': 'Resp chest',
  'BRUSTATMUNG': 'Resp chest',
  'ABD': 'Resp abdomen',
  'ABDO': 'Resp abdomen',
  'ABDOMEN': 'Resp abdomen',
  'RESP.ABD.': 'Resp abdomen',
  'ABDOMINALMOV.': 'Resp abdomen',
  'ABDOMINAKMOVEME': 'Resp abdomen',
  'EFFORTABD': 'Resp abdomen',
  'ABDOMEN,EFFORT': 'Resp abdomen',
  'BAUCHATMUNG': 'Resp abdomen',
  'SNORE': 'Sound snoring',
  'SNORE_CU': 'Sound snoring',
  'SNORING': 'Sound snoring',
  'SNORINGSENSOR': 'Sound snoring',
  'SCHNARCHEN_CU': 'Sound snoring',
  'PNEUMOSCHNARCH': 'Sound snoring',
  'SAT': 'SaO2',
  'O2-SAT.': 'SaO2',
  'SPO2': 'SaO2',
  'SAO2,SAO2': 'SaO2',
  'SPO2BB': 'SaO2',
  'GND-SPO2': 'SaO2',
  'OXYMETRY': 'SaO2',
  'OXYGENSAT.': 'SaO2',
  'POS': 'Position body',
  'POSITION': 'Position body',
  'POSSENSOR': 'Position body',
  'POSITION_CU': 'Position body',
  'BODYPOSITION': 'Position body',
  'BODY': 'Position body',
  'LAGE': 'Position body',
  'KÖRPERLAGE_CU': 'Position body',
  'MANUALPOS': 'Position body',
  'A1': 'EEG A1',
  'M1': 'EEG A1',
  'EEGA1': 'EEG A1',
  'A2': 'EEG A2',
  'M2': 'EEG A2',
  'EEGA2': 'EEG A2',
  'A1-A2': 'EEG A1-A2',
  'M1-M2': 'EEG A1-A2',
  'EEGA1-A2': 'EEG A1-A2',
  'EEG(M1-M2)': 'EEG A1-A2',
  'M2-M1': 'EEG A1-A2',
  'M2M1': 'EEG A1-A2',
  'A2-M1': 'EEG A1-A2',
  'M2-M1,EEG': 'EEG A1-A2',
  'A2-A1': 'EEG A2-A1',
  'C3': 'EEG C3',
  'EEGC3': 'EEG C3',
  'C4': 'EEG C4',
  'EEGC4': 'EEG C4',
  'CZ': 'EEG Cz',
  'CZ-M1': 'EEG Cz-A1',
  'CZ-M1,EEG': 'EEG Cz-A1',
  'F3': 'EEG F3',
  'EEGF3': 'EEG F3',
  'F3-CZ_': 'EEG F3-Cz',
  'EEGF3-CZ': 'EEG F3-Cz',
  'F4': 'EEG F4',
  'EEGF4': 'EEG F4',
  'F4-CZ_': 'EEG F4-Cz',
  'EEGF4-CZ': 'EEG F4-Cz',
  'O1': 'EEG O1',
  'EEGO1': 'EEG O1',
  'O2': 'EEG O2',
  'EEGO2': 'EEG O2',
  'F7': 'EEG F7',
  'F7-M2,EEG': 'EEG F7-A2',
  'F7-M2,POS': 'EEG F7-A2',
  'F8': 'EEG F8',
  'F8-M1,EEG': 'EEG F8-A1',
  'FP1': 'EEG Fp1',
  'FP1M2': 'EEG Fp1-A2',
  'FP1-M2': 'EEG Fp1-A2',
  'FP1-A2': 'EEG Fp1-A2',
  'FP1-M2,EEG': 'EEG Fp1-A2',
  'FP2': 'EEG Fp2',
  'FP2M1': 'EEG Fp2-A1',
  'FP2-M1': 'EEG Fp2-A1',
  'FP2-A1': 'EEG Fp2-A1',
  'FP2-M1,EEG': 'EEG Fp2-A1',
  'FZ': 'EEG Fz',
  'FZ-M1': 'EEG Fz-A1',
  'FZ-M1,EEG': 'EEG Fz-A1',
  'P3': 'EEG P3',
  'P3-M2,EEG': 'EEG P3-A2',
  'P4': 'EEG P4',
  'P4-M1,EEG': 'EEG P4-A1',
  'PZ': 'EEG Pz',
  'PZ-M1': 'EEG Pz-A1',
  'PZ-M1,EEG': 'EEG Pz-A1',
  'T3': 'EEG T3',
  'T3-M2,EEG': 'EEG T3-A2',
  'T4': 'EEG T4',
  'T4-M1,EEG': 'EEG T4-A1',
  'T5': 'EEG T5',
  'T5-M2,EEG': 'EEG T5-A2',
  'T6': 'EEG T6',
  'T6-M1,EEG': 'EEG T6-A1',
  'EMGRARM': 'EMG RArm',
  'R-ARM': 'EMG RArm',
  'EMGEMGARMRI': 'EMG RArm',
  'EMGLARM': 'EMG LArm',
  'L-ARM': 'EMG LArm',
  'EMGEMGARMLE': 'EMG LArm',
  'EMGCHIN-SUBMENT': 'EMG chin-subment',
  'KINN(S)': 'EMG chin-subment',
  'SUBMENT': 'EMG chin-subment',
  'EMGSUBM.': 'EMG chin-subment',
  'EMG-SUBM': 'EMG chin-subment',
  'EMGSUBMENTAL': 'EMG chin-subment',
  'Subm. EMG': 'EMG chin-subment',
  'EMG-X5': 'EMG X5',
  'EMG-X6': 'EMG X6',
  'EMG-X7': 'EMG X7',
  'EMG-X8': 'EMG X8',
  'LIGHT': 'Event light',
  'LICHT_CU': 'Event light',
  'PCO2END-TIDAL': 'PCO2 end-tidal',
  'ETCO2': 'PCO2 end-tidal',
  'CO2ENDTIDAL': 'PCO2 end-tidal',
  'PCO2TRANSCUTAN': 'PCO2 transcutan',
  'TCPCO2': 'PCO2 transcutan',
  'RESPORO-NASAL': 'Resp oro-nasal',
  'ORA': 'Resp oral',
  'RESPORAL': 'Resp oral',
  'RESPCPAP': 'Resp CPAP',
  'CPAP': 'Resp CPAP',
  'XPAPCPAP': 'Resp CPAP',
  'CPAPFLOW': 'Resp CPAP',
  'CPAPPRESS': 'Resp CPAP',
  'CPAPPRESSURE': 'Resp CPAP',
  'RESPVOLUME': 'Resp Volume',
  'PLETH': 'Resp Volume',
  'TIDVOL': 'Resp Volume',
  'PLETHYSMOGRAM': 'Resp Volume',
  'TIDALVOLUME_CU': 'Resp Volume',
  'ATEMZUGVOLUMEN_C': 'Resp Volume',
  'RESPIRATORYRATE': 'Result resprate',
  'RESULTHEARTRATE': 'Result heartrate',
  'RR': 'Result heartrate',
  'RR_CU': 'Result heartrate',
  'DERIVEDHR': 'Result heartrate',
  'HEARTRATE_CU': 'Result heartrate',
  'HERZFREQUENZ_CU': 'Result heartrate',
  'RESULTPULSE': 'Result pulse',
  'PULS': 'Result pulse',
  'PULSE': 'Result pulse',
  'PULSRATE_': 'Result pulse',
  'PULSERATE': 'Result pulse',
  'PULSETEST': 'Result pulse',
  'SAO2QUALITY': 'SaO2 Quality',
  'OXSTATUS': 'SaO2 Quality',
  'SPO2-QUALITY_CU': 'SaO2 Quality',
  'SPO2-QUALITÄT_CU': 'SaO2 Quality',
  'SOUNDRAW': 'Sound raw',
  'SOUND': 'Sound raw',
  'TEMPBODY': 'Temp body',
  'Temp. body': 'Temp body',
  'KORPERKERNTEMP': 'Temp body',
  'TESTIMPEDANCE': 'Test impedance',
  'IMP': 'Test impedance',
  // '-': 'unknown',
  // 'ÖSOPHAGUSDRUCK': 'unknown',
  // 'ACTIVITY_CU': 'unknown',
  // 'AKTIVITÄT_CU': 'unknown',
  // 'CO2WAVE': 'unknown',
  // 'ELEVATION_CU': 'unknown',
  // 'EMG(L+RTIB.)': 'unknown',
  // 'EMGBEIN': 'unknown',
  // 'EMGFDSLEFT': 'unknown',
  // 'EMGFDSRIGHT': 'unknown',
  // 'EMGLINKED': 'unknown',
  // 'EMG-LINKED': 'unknown',
  // 'EMGLINKED,EM': 'unknown',
  // 'EMG-LINKED,EM': 'unknown',
  // 'EMGLINKED,EMG': 'unknown',
  // 'EMGL/RAT': 'unknown',
  // 'EMGTIB.ANT.': 'unknown',
  // 'EMGTIBIALIS': 'unknown',
  // 'ERHEBUNG_CU': 'unknown',
  // 'FP-M2': 'unknown',
  // 'GRAVITYX': 'unknown',
  // 'GRAVITYY': 'unknown',
  // 'HERZFREQUENZVARI': 'unknown',
  // 'LEAKTOTAL': 'unknown',
  // 'NAF': 'unknown',
  // 'PAT-EVENT': 'unknown',
  // 'PH-D': 'unknown',
  // 'PHASE_CU': 'unknown',
  // 'PRESSURE': 'unknown',
  // 'R-RINTERVALLE': 'unknown',
  // 'RD-QUALITY': 'unknown',
  // 'RMI_CU': 'unknown',
  // 'SUM': 'unknown',
  // 'XSUM_CU': 'unknown',
};

export const channelNumberMap = {
  'EEG A1': 0,
  'EEG A1-A2': 0,
  'EEG A2': 0,
  'EEG A2-A1': 0,
  'EEG C3': 0,
  'EEG C4': 0,
  'EEG Cz': 0,
  'EEG Cz-A1': 0,
  'EEG E1': 0,
  'EEG E2': 0,
  'EEG F3': 0,
  'EEG F3-Cz': 0,
  'EEG F4': 0,
  'EEG F4-Cz': 0,
  'EEG F7': 0,
  'EEG F7-A2': 0,
  'EEG F8': 0,
  'EEG F8-A1': 0,
  'EEG Fp1': 0,
  'EEG Fp1-A2': 0,
  'EEG Fp2': 0,
  'EEG Fp2-A1': 0,
  'EEG Fz': 0,
  'EEG Fz-A1': 0,
  'EEG O1': 0,
  'EEG O2': 0,
  'EEG P3': 0,
  'EEG P3-A2': 0,
  'EEG P4': 0,
  'EEG P4-A1': 0,
  'EEG Pz': 0,
  'EEG Pz-A1': 0,
  'EEG T3': 0,
  'EEG T3-A2': 0,
  'EEG T4': 0,
  'EEG T4-A1': 0,
  'EEG T5': 0,
  'EEG T5-A2': 0,
  'EEG T6': 0,
  'EEG T6-A1': 0,
  'EMG chin-subment': 0,
  'EMG LArm': 0,
  'EMG RArm': 0,
  'EMG X5': 0,
  'EMG X6': 0,
  'EMG X7': 0,
  'EMG X8': 0,
  'Event light': 0,
  'PCO2 end-tidal': 0,
  'PCO2 transcutan': 0,
  'Resp CPAP': 0,
  'Resp oral': 0,
  'Resp oro-nasal': 0,
  'Resp Volume': 0,
  'Result heartrate': 0,
  'Result pulse': 0,
  'Result resprate': 0,
  'SaO2 Quality': 0,
  'Sound raw': 0,
  'Temp body': 0,
  'Test impedance': 0,
  'unknown': 0,
  'EEG F4-A1': 1,
  'EEG C4-A1': 2,
  'EEG O2-A1': 3,
  'EEG F3-A1': 4,
  'EEG F3-A2': 4,
  'EEG C3-A2': 5,
  'EEG O1-A2': 6,
  'EOG E1': 7,
  'EOG E1-A': 7,
  'EOG E1-A1': 7,
  'EOG E1-A2': 7,
  'EOG vertical': 7,
  'EOG E2': 8,
  'EOG E2-A1': 8,
  'EOG E2-A2': 8,
  'EOG horizontal': 8,
  'EMG chin': 9,
  'EMG RAT': 10,
  'EMG UAT': 10,
  'EMG LAT': 11,
  'ECG': 12,
  'Resp nasal': 13,
  'Resp chest': 14,
  'Resp abdomen': 15,
  'Sound snoring': 16,
  'SaO2': 17,
  'Position body': 18,
};

export default { channelNameMap, channelNumberMap };
